import BOUNDARY_CROSS_LOG_ACTION_TYPES from './boundary-cross-log.types';

export const BOUNDARY_CROSS_LOG_INITIAL_STATE = {
  boundaryCrossLogLists: [],
};

export const BoundaryCrossLogReducer = (state = BOUNDARY_CROSS_LOG_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BOUNDARY_CROSS_LOG_ACTION_TYPES.LOAD_BOUNDARY_CROSS_LOGS: {
      return { ...state, boundaryCrossLogLists: payload };
    }
    default:
      return state;
  }
};
