// assets
import { IconMapPinOff } from '@tabler/icons';

// constant
const icons = {
  IconMapPinOff
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const boundaryCrossLogs = {
  id: 'boundaryCrossLogs',
  title: 'Boundary Cross Logs',
  type: 'group',
  children: [
    {
      id: 'boundary-cross-logs-default',
      title: 'Boundary Cross Logs',
      type: 'item',
      url: '/boundary-cross-logs',
      icon: icons.IconMapPinOff,
      breadcrumbs: false
    }
  ]
};

export default boundaryCrossLogs;
