import DASHBOARD_ACTION_TYPES from './dashboard.types';

export const DASHBOARD_INITIAL_STATE = {
  dashboardLists: [],
};

export const DashboardReducer = (state = DASHBOARD_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_ACTION_TYPES.LOAD_DASHBOARD: {
      return { ...state, dashboardLists: payload };
    }
    default:
      return state;
  }
};
