import { createSelector } from "reselect";

const selectPunchReducer = (state) => {
  return state.punch.punchLists
};

export const selectPunch = createSelector(
  [selectPunchReducer],
  (state) => {
    return state
  }
);