import dashboard from './dashboard';
import admins from './admins';
import users from './users';
import notifications from './notifications';
import locations from './location';
import punch from './punch';
import boundaryCrossLogs from './boundaryCrossLogs';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, admins, users, notifications, locations, punch, boundaryCrossLogs]
};

export default menuItems;
