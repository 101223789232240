import { createSelector } from "reselect";

const selectRegistrationRequestReducer = (state) => {
  return state.registrationRequest.registrationRequestLists
};

export const selectRegistrationRequest = createSelector(
  [selectRegistrationRequestReducer],
  (state) => {
    return state
  }
);