import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from '../views/admins/AdminRoutes';
import UserRoutes from 'views/users/UserRoutes';
import RegistrationRequestRoutes from 'views/registration-requests/RegistrationRequestRoutes';
import NotificationRoutes from 'views/notifications/NotificationRoutes';
import LocationRoutes from 'views/locations/LocationRoutes';
import PunchRoutes from 'views/punch/PunchRoutes';
import BoundaryCrossLogsRoutes from 'views/boundary-cross-logs/BoundaryCrossLogsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AdminRoutes, UserRoutes, NotificationRoutes, RegistrationRequestRoutes, LocationRoutes, PunchRoutes, BoundaryCrossLogsRoutes, AuthenticationRoutes]);
}
