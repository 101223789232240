import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { boundaryCrossLogList } from "services/api/boundaryCrossLog";
import { setBoundaryCrossLogList } from "store/boundary-cross-log/boundary-cross-log.action";
import { selectBoundaryCrossLog } from "store/boundary-cross-log/boundary-cross-log.selector";
import GoogleMapComponent from "ui-component/GoogleMapComponent";
import Datatable from "ui-component/tables/Datatable";
import { IconMapPin } from "@tabler/icons";


import { isObjectEmpty } from "utils/helper";
import { Chip, IconButton } from "@mui/material";

const BoundaryCrossLogs = () => {
  const title = "Boundary Cross Logs";
  const dispatch = useDispatch();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setBoundaryCrossLogs = () => {
    boundaryCrossLogList()
      .then((res) => {
        dispatch(setBoundaryCrossLogList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setBoundaryCrossLogs();
    setLoading(false);
  }, []);

  const handleLocationView = (event, lat, long) => {
    updateState(
      "User Boundary Cross Location Location",
      () => {
        return <GoogleMapComponent
          latitude={Number(lat)}
          longitude={Number(long)}
          loadingElement={<div style={{ height: '200%', width: '200%' }} />}
          containerElement={<div style={{ height: '380px', width: '380px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      },
      () => { },
      true
    );
  };


  const boundaryCrossLogs = useSelector(selectBoundaryCrossLog);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "user",
      label: "User",
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          let color = (valueData == 'Left the boundary location') ? "error" : "success";
          return (
            <Chip label={valueData} color= {color} />
          );
        },
      },
    },
    // {
    //   name: "location",
    //   label: "View location",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       console.log(value);
    //       return (
    //         <>
    //           <IconButton
    //             onClick={(e) => {
    //               handleLocationView(e, value.latitude, value.longitude);
    //             }}
    //             color="secondary"
    //           >
    //             <IconMapPin />
    //           </IconButton>
    //         </>
    //       );
    //     },
    //   },
    // },
  ];

  const data = isObjectEmpty(boundaryCrossLogs)
    ? []
    : boundaryCrossLogs.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.user,
        item.created_at,
        item.is_user_outside_boundary ? 'Left the boundary location' : 'Enter the boundary location',
        // item.location,
      ];
    });


  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };

  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => { }}
    />);
};

export default BoundaryCrossLogs;
