import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { AdminReducer } from './admin/admin.reducer';
import { UserReducer } from './user/user.reducer';
import { RegistrationRequestReducer } from './registration-request/registration-request.reducer';
import { NotificationReducer } from './notification/notification.reducer';
import { PunchReducer } from './punch/punch.reducer';
import { BoundaryCrossLogReducer } from './boundary-cross-log/boundary-cross-log.reducer';
import { DashboardReducer } from './dashboard/dashboard.reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    admin: AdminReducer,
    user: UserReducer,
    registrationRequest: RegistrationRequestReducer,
    notification: NotificationReducer,
    punch: PunchReducer,
    boundaryCrossLog: BoundaryCrossLogReducer,
    dashboard: DashboardReducer,
});

export default reducer;
