// assets
import { IconUserCheck, IconUserPlus } from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconUserPlus
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const users = {
  id: 'users',
  title: 'Users',
  type: 'group',
  children: [
    {
      id: 'user-default',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.IconUserCheck,
      breadcrumbs: false
    },
    {
      id: 'user-registration-request',
      title: 'Registration Request',
      type: 'item',
      url: '/registration-requests',
      icon: icons.IconUserPlus,
      breadcrumbs: false
    }
  ]
};

export default users;
