import { LoadingButton } from '@mui/lab';
import { Box, Button, Container } from '@mui/material';
import { GoogleMap, useLoadScript, Marker, Polygon } from '@react-google-maps/api';
import config from 'config';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { boundaryCreate } from 'services/api/boundaryLocation';
import { getUserBoundaryLocation } from 'services/api/user';
import Breadcrumb from 'ui-component/Breadcrumb';
import SimpleCard from 'ui-component/SimpleCard';
import { capitalizeFirstLetter } from 'utils/helper';

const Map = () => {
    const title = 'users';
    const libraries = ['places'];

    const [pin, setPin] = useState(null);
    const [drawingMode, setDrawingMode] = useState(true);
    const [loading, setLoading] = useState(false);
    const [polygonCoords, setPolygonCoords] = useState([]);
    const [isPinInsidePolygon, setIsPinInsidePolygon] = useState(null);
    const { userId } = useParams();
    const { openSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        getUserBoundaryLocation(userId).then((res) => {
            // [
            //     { lat: 29.34918133550366, lng: 47.408191551271074 },
            //     { lat: 29.310869255274863, lng: 47.4864691391617 },
            //     { lat: 29.351575362521583, lng: 47.537280906739824 },
            // ]
            console.log(res.data.result.boundary_location)
            setPolygonCoords(res.data.result.boundary_location.map(item => {
                return {
                    lat: Number(item.latitude),
                    lng: Number(item.longitude)
                }
            }))
        }).catch((error) => {
            console.log(error)
            openSnackbar("error", error.data.message);
        });
    }, [])

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.googleMapApiKey,
        libraries,
    });

    const mapContainerStyle = {
        width: '70vw',
        height: '70vh',
    };

    const center = {
        lat: 29.3117, // default latitude
        lng: 47.4818, // default longitude
    };

    const handlePolygonComplete = (polygon) => {
        const paths = polygon.getPath().getArray().map((point) => {
            return { lat: point.lat(), lng: point.lng() };
        });
        setPolygonCoords(paths);
        console.log(paths);
    };

    const handlePolygonClear = () => {
        setPolygonCoords([]);
    };

    const handleSubmit = () => {
        setLoading(true);
        let formData = {
            user_id: userId,
            polygon_data: polygonCoords.map(item => {
                return {
                    latitude: item.lat,
                    longitude: item.lng
                }
            })
        };

        boundaryCreate(formData).then((res) => {
            setLoading(false);
            openSnackbar("success", res.data.message)
            navigate("/users");
        }).catch((e) => {
            if (e.response.status == 422) {

            }
            setLoading(false);
        });

        console.log(formData);
    };

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: capitalizeFirstLetter(title), path: `/${title}` },
                        { name: 'User Map' },
                    ]}
                />
            </Box>
            <SimpleCard title="Map">
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                        onClick={(event) => {
                            if (drawingMode) {
                                setPolygonCoords([...polygonCoords, event.latLng.toJSON()]);
                            } else {
                                setPin(event.latLng.toJSON());
                            }
                        }}
                    >
                        {drawingMode && (
                            <Polygon
                                path={polygonCoords}
                                options={{
                                    strokeColor: "#FF0000", // Red stroke color
                                    fillColor: "#FF0000",   // Green fill color
                                    // You can also specify other options here, such as strokeOpacity, fillOpacity, etc.
                                }}
                                editable
                                draggable
                                onMouseUp={() => handlePolygonComplete}
                            />
                        )}
                    </GoogleMap>
                )}
                {!isLoaded && <div>Loading maps</div>}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    <LoadingButton
                        type="submit"
                        color="primary"
                        loading={loading}
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ my: 4 }}
                    >
                        Submit
                    </LoadingButton>
                    <Button
                        type="button"
                        color="error"
                        onClick={handlePolygonClear}
                        variant="contained"
                        sx={{ my: 4 }}
                    >
                        Reset Polygon
                    </Button>
                </Box>
            </SimpleCard>
        </Container>
    );
};

export default Map;
