// assets
import { IconBell } from '@tabler/icons';

// constant
const icons = {
    IconBell
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const notifications = {
  id: 'notifications',
  title: 'Notifications',
  type: 'group',
  children: [
    {
      id: 'notification-default',
      title: 'Notifications',
      type: 'item',
      url: '/notifications',
      icon: icons.IconBell,
      breadcrumbs: false
    }
  ]
};

export default notifications;
