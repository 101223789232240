import REGISTRATION_REQUEST_ACTION_TYPES from './registration-request.types';

export const REGISTRATION_REQUEST_INITIAL_STATE = {
  registrationRequestLists: [],
};

export const RegistrationRequestReducer = (state = REGISTRATION_REQUEST_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTRATION_REQUEST_ACTION_TYPES.LOAD_REGISTRATION_REQUESTS: {
      return { ...state, registrationRequestLists: payload };
    }
    default:
      return state;
  }
};
