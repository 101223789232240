import React from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const GoogleMapComponent = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
  >
    <Marker position={{ lat: props.latitude, lng: props.longitude }} />

  </GoogleMap>
));

export default GoogleMapComponent
