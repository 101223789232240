import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import config from 'config';
import SimpleCard from 'ui-component/SimpleCard';
import { capitalizeFirstLetter, isObjectEmpty } from 'utils/helper';
import Breadcrumb from 'ui-component/Breadcrumb';
import { Box, Container, Typography } from '@mui/material';
import { userListWithLastlocation } from 'services/api/user';
import useSnackbar from 'hooks/useSnackbar';
import socketService from 'services/socketService';
import useNotification from 'hooks/useNotification';

const libraries = ['places', 'geometry'];
const mapContainerStyle = {
    width: '70vw',
    height: '70vh',
};
const center = {
    lat: 29.3117, // default latitude
    lng: 47.4818, // default longitude
};

const Location = () => {
    const { notifications } = useNotification();

    // useEffect(() => {
    //     userListWithLastlocation()
    //         .then((res) => {
    //             const locationData = res.data.result.data
    //                 .filter(item => item.last_location)
    //                 .map(item => ({
    //                     user_id: item.id,
    //                     name: item.name,
    //                     position: {
    //                         lat: Number(item.last_location.latitude),
    //                         lng: Number(item.last_location.longitude),
    //                     },
    //                     boundaries: item.user_boundary.length === 0 ? false : item.user_boundary.map(item => {
    //                         return {
    //                             lat: Number(item.latitude),
    //                             lng: Number(item.longitude),
    //                         }
    //                     })
    //                 }));
    //             setPin(locationData);
    //             console.log(locationData);
    //         });

    //         return () => {
    //             socketService.disconnect();
    //             console.log('disconnected');
    //         };
    // }, []);

    // useEffect(() => {
    //     // Connect to the socket when the component mounts
    //     socketService.connect();
    //     console.log('connected');
        
    //     socketService.on('on-new-location', (data) => {
    //         let locationData = JSON.parse(data);
    //         console.log('socket event')
    //         console.log('pin ',pin);
    //         let updatedPin = pin.map(item => {
    //             console.log('event data', locationData)
    //             console.log('user ',item);
    //             // google.maps.geometry.poly.containsLocation
    //             if(item.boundaries){
    //                 console.log('poly loc ',{point: {lat: locationData.latitude, lng: locationData.longitude}, boundary: item.boundaries});
    //                 let polygon =  new window.google.maps.Polygon({ paths: item.boundaries });
    //                 let isUserInsideBoundary = window.google.maps.geometry.poly.containsLocation({lat: locationData.latitude, lng: locationData.longitude}, polygon)
    //                 if(!isUserInsideBoundary){
    //                     //process entry for boundary cross log
    //                     openSnackbar('error', `User ${item.name} is outside boundary`)
    //                 }
    //             }
    //             if(item.user_id == locationData.user_id){
    //                 return{
    //                     ...item, position: {lat: locationData.latitude, lng: locationData.longitude}, updated: true
    //                 }
    //             } else {
    //                 return item;
    //             }
    //         })
    //         console.log('updated pin ',updatedPin);
    //     });
    //     // Disconnect from the socket when the component unmounts
    //     return () => {
    //         socketService.disconnect();
    //         console.log('disconnected');
    //     };
    // }, [pin]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapApiKey,
        libraries,
    });

    if (loadError) return <div>Error loading maps</div>;

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: capitalizeFirstLetter('locations'), path: "/locations" },
                        { name: "Live Location Pin" },
                    ]}
                />
            </Box>
            <SimpleCard title="Location">
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                    >
                        {!isObjectEmpty(notifications.locations) && notifications.locations.map(item => (

                            <Marker
                            position={item.position}
                            key={item.user_id}
                            label={{text: item.name, className:'marker-label'}}
                            >
                            </Marker>
                        ))}
                    </GoogleMap>
                )}
                {!isLoaded && <div>Loading maps</div>}
            </SimpleCard>
        </Container>
    );
};

export default Location;