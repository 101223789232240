import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Locations from "./Location";

 

const LocationRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/locations",
            element:<Locations/>
        }
    ]
}

export default LocationRoutes;