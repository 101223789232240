import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Punch from "./Punch";

 

const PunchRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/punch",
            element:<Punch/>
        }
    ]
}

export default PunchRoutes;