import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Notification from "./Notification";

 

const NotificationRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/notifications",
            element:<Notification/>
        }
    ]
}

export default NotificationRoutes;