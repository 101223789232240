import { useSelect } from "@mui/base";
import { Button, Icon, IconButton } from "@mui/material";
import { IconMapPin } from "@tabler/icons";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { punchList } from "services/api/punch";
import { setPunchList } from "store/punch/punch.action";
import { selectPunch } from "store/punch/punch.selector";
import GoogleMapComponent from "ui-component/GoogleMapComponent";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";

const Punch = () => {
  const title = "Punch";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setPunch = () => {
    punchList()
      .then((res) => {
        dispatch(setPunchList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setPunch();
    setLoading(false);
  }, []);

  const handleLocationView = (event, lat, long) => {
    updateState(
      "Punch Location",
      () => {
        return <GoogleMapComponent
          latitude={Number(lat)}
          longitude={Number(long)}
          loadingElement={<div style={{ height: '200%', width: '200%' }} />}
          containerElement={<div style={{ height: '380px', width: '380px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      },
      () => { },
      true
    );
  };


  const punch = useSelector(selectPunch);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "user.name",
      label: "User Name",
    },
    {
      name: "type",
      label: "Punch Type",
    },
    {
      name: "user.location",
      name: "Location",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(value);
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleLocationView(e, value.latitude, value.longitude);
                }}
                color="secondary"
              >
                <IconMapPin />
              </IconButton>
            </>
          );
        },
      },
    },
    {
      label: "Create Date",
      name: 'created_at',
      options: {
        filter: false,
      },
    },
   
  ];
  const data = isObjectEmpty(punch)
    ? []
    : punch.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.user.name,
        item.type,
        item.location,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => {}}
    />);
};

export default Punch;
