import { IconButton } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import useAuth from "hooks/useAuth";
import EditIcon from '@mui/icons-material/Edit';
import { setRegistrationRequestList } from "store/registration-request/registration-request.action";
import { registrationRequestList } from "services/api/registration-request";
import { selectRegistrationRequest } from "store/registration-request/registration-request.selector";


const RegistrationRequest = () => {
  const title = "Registration Requests";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setRegistrationRequest = () => {
    registrationRequestList()
      .then((res) => {
        dispatch(setRegistrationRequestList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setRegistrationRequest();
    setLoading(false);
  }, []);

  const handleEdit = (id, row) => {
    navigate("/registration-requests/view/" + row.rowData[0]);
  };

  const registrationRequest = useSelector(selectRegistrationRequest);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "civil_id",
      label: "Civil Id",
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data = isObjectEmpty(registrationRequest)
    ? []
    : registrationRequest.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.name,
        item.civil_id,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => {}}
    />);
};

export default RegistrationRequest;
