import PUNCH_ACTION_TYPES from './punch.types';

export const PUNCH_INITIAL_STATE = {
  punchLists: [],
};

export const PunchReducer = (state = PUNCH_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PUNCH_ACTION_TYPES.LOAD_PUNCH: {
      return { ...state, punchLists: payload };
    }
    default:
      return state;
  }
};
