import { useLoadScript } from '@react-google-maps/api';
import config from 'config';
import useAuth from 'hooks/useAuth';
import useSnackbar from 'hooks/useSnackbar';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { notificationList } from 'services/api/adminNotification';
import { boundaryCrossLogCreate } from 'services/api/boundaryCrossLog';
import { userListWithLastlocation } from 'services/api/user';
import socketService from 'services/socketService';

const initialState = {
    notifications: [],
    locations: []
};

const libraries = ['places', 'geometry'];

const NotificationContext = createContext({
    notifications: {},
    addNotification: () => {},
    removeNotification: () => {},
    clearNotification: () => {}
});

export const NotificationProvider = ({ children }) => {
    const handleBoundaryCross = (data, isOutsideBoundary) => {
        boundaryCrossLogCreate(data).then((res) => {
            console.log(res.data.result);
            dispatch({
                type: 'ADD_NOTIFICATION',
                payload: { id: res.data.result.id, type: 'Boundary Cross', date: res.data.result.created_at, user: res.data.result.user }
            });
            if(isOutsideBoundary){
                openSnackbar('warning', `User ${res.data.result.user} have crossed the boundary line`);
            } else {
                openSnackbar('warning', `User ${res.data.result.user} have entered back the boundary line`);

            }
        });
    };

    const reducer = (state, action) => {
        console.log('type: ', action.type);
        console.log('payload: ', action.payload);
        console.log('state: ', state);
        switch (action.type) {
            case 'INIT': {
                return action.payload;
            }
            case 'ADD_NOTIFICATION': {
                const data = action.payload;
                return {
                    ...state,
                    notifications: [...state.notifications, data]
                };
            }
            case 'REMOVE_NOTIFICATION': {
                const data = action.payload;
                return {
                    ...state,
                    notifications: state.notifications.filter((item) => item.id !== data)
                };
            }
            case 'CLEAR_NOTIFICATION': {
                return {
                    ...state,
                    notifications: []
                };
            }
            case 'SET_LOCATIONS': {
                const data = action.payload;
                return {
                    ...state,
                    locations: data
                };
            }
            case 'UPDATE_LOCATION': {
                let data = action.payload;
                let updatedState = state.locations.map((item) => {
                    if (item.user_id == data.user_id) {
                        if (item.boundaries) {
                            // check boundary cross and set outside boundary true
                            let polygon = new window.google.maps.Polygon({ paths: item.boundaries });

                            let isUserInsideBoundary = window.google.maps.geometry.poly.containsLocation(
                                { lat: data.latitude, lng: data.longitude },
                                polygon
                            );
                            console.log('item: ', item)
                            console.log('inside boundary: ', isUserInsideBoundary)
                            if (item.is_outside_boundary == isUserInsideBoundary) {
                                data['is_user_outside_boundary'] = !isUserInsideBoundary == true ? 1 : 0;
                                handleBoundaryCross(data, !isUserInsideBoundary);
                            }

                            return {
                                ...item,
                                position: { lat: data.latitude, lng: data.longitude },
                                updated: true,
                                is_outside_boundary: !isUserInsideBoundary
                            };
                        } else {
                            // set outside boundary false

                            return {
                                ...item,
                                position: { lat: data.latitude, lng: data.longitude },
                                updated: true,
                                is_outside_boundary: false
                            };
                        }
                    } else {
                        return item;
                    }
                });

                return {
                    ...state,
                    locations: updatedState
                };
            }
            default: {
                return { ...state };
            }
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    let { isAuthenticated, isInitialised, user } = useAuth();
    const { openSnackbar } = useSnackbar();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapApiKey,
        libraries
    });

    const handleAddNotification = (notification) => {
        // setCurrentNotification([...currentNotification, notification]);
        // dispatch({
        //     type: 'CLEAR_NOTIFICATION',
        //     payload: {}
        // });
    };

    const handleRemoveNotification = (notification_id) => {
        dispatch({
            type: 'REMOVE_NOTIFICATION',
            payload: notification_id
        });
    };

    const handleClearNotification = () => {
        dispatch({
            type: 'CLEAR_NOTIFICATION',
            payload: {}
        });
    };

    useEffect(() => {
        if (isInitialised && isAuthenticated) {
            // Connect to the socket when the component mounts
            socketService.connect();

            notificationList().then((res) => {
                dispatch({
                    type: 'INIT',
                    payload: { locations: state.locations, notifications: res.data.result.data }
                });
                console.log('state after init: ', state);

                userListWithLastlocation().then((res) => {
                    
                    const locationData = res.data.result.data
                        .filter((item) => item.last_location)
                        .map((item) => ({
                            user_id: item.id,
                            name: item.name,
                            position: {
                                lat: Number(item.last_location.latitude),
                                lng: Number(item.last_location.longitude)
                            },
                            boundaries:
                                item.user_boundary.length === 0
                                    ? false
                                    : item.user_boundary.map((item) => {
                                          return {
                                              lat: Number(item.latitude),
                                              lng: Number(item.longitude)
                                          };
                                      }),
                            is_outside_boundary: item.is_outside_boundary
                        }));

                    dispatch({
                        type: 'SET_LOCATIONS',
                        payload: locationData
                    });
                });
            });

            socketService.on('on-new-punch', (data) => {
                let punchData = JSON.parse(data);
                let punchType = punchData.type == 'finger' ? 'Finger Punch' : 'Face Punch';
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { id: punchData.id, type: punchType, date: punchData.created_at, user: punchData.name }
                });
                openSnackbar('warning', `User ${punchData.name} has punch in`);
            });

            socketService.on('on-new-registration', (data) => {
                let registrationData = JSON.parse(data);
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: {
                        id: registrationData.id,
                        type: 'Registration Request',
                        date: registrationData.created_at,
                        user: registrationData.name
                    }
                });
                openSnackbar('warning', `New Registation Request has been sent to admin`);
            });

            socketService.on('on-new-location', (data) => {
                let locationData = JSON.parse(data);
                dispatch({
                    type: 'UPDATE_LOCATION',
                    payload: locationData
                });
            });
        }

        return () => {
            if (isInitialised && isAuthenticated) {
                // Disconnect from the socket when the component unmounts
                socketService.disconnect();
            }
        };
    }, [isInitialised, isAuthenticated]);

    return (
        <NotificationContext.Provider
            value={{
                notifications: state,
                addNotification: handleAddNotification,
                removeNotification: handleRemoveNotification,
                clearNotification: handleClearNotification
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
