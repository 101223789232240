import NOTIFICATION_ACTION_TYPES from './notification.types';

export const NOTIFICATION_INITIAL_STATE = {
  notificaitonLists: [],
};

export const NotificationReducer = (state = NOTIFICATION_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATION_ACTION_TYPES.LOAD_NOTIFICATIONS: {
      return { ...state, notificationLists: payload };
    }
    default:
      return state;
  }
};
