import { useSelect } from "@mui/base";
import { Button, Icon, IconButton } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { adminDelete, adminList, adminUpdateStatus } from "services/api/admin";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "hooks/useAuth";
import { setAdminList } from "store/admin/admin.action";
import { selectAdmin } from "store/admin/admin.selector";
const Admin = () => {
  const title = "Admins";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setAdmin = () => {
    adminList()
      .then((res) => {
        dispatch(setAdminList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  const { user } = useAuth();
  useEffect(() => {
    setLoading(true);
    setAdmin();
    setLoading(false);
  }, []);

  const deleteHandler = (id) => {
    setLoading(false);
    adminDelete(id)
      .then((res) => {
        setAdmin();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };

  const handleEdit = (id, row) => {
    navigate("/admins/edit/" + row.rowData[0]);
  };

  const handleStatusUpdate = (id, value) => {
    adminUpdateStatus(id)
      .then((res) => {
        setAdmin();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
  };

  const handleDelete = (event, row) => {
    updateState("Admin Delete", 'Are you sure you want to delete Admin', () => deleteHandler(row["rowData"][0]));
  };

  const admins = useSelector(selectAdmin);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            (tableMeta.rowData[0] == user?.id) ?
              'Active'
              :
              <SimpleSwitch
                id={tableMeta["rowData"][0]}
                value={valueData}
                changeHandler={(id, valueData) => handleStatusUpdate(id, valueData)}
              />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data = isObjectEmpty(admins)
    ? []
    : admins.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.name,
        item.email,
        item.status,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("/admins/create")}
    />);
};

export default Admin;
