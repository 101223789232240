// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = { IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admins = {
  id: 'admins',
  title: 'Admin',
  type: 'group',
  children: [
    {
      id: 'default-admin',
      title: 'Admin',
      type: 'item',
      url: '/admins',
      icon: icons.IconUsers,
      breadcrumbs: false
    }
  ]
};

export default admins;
