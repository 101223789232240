import { useSelect } from "@mui/base";
import { Button, Icon, IconButton } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { adminDelete, adminList, adminUpdateStatus } from "services/api/admin";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";
import { selectNotification } from "store/notification/notification.selector";
import { setNotificationList } from "store/notification/notification.action";
import { notificationList } from "services/api/notification";
import NotificationForm from "ui-component/NotificationForm";
import { userList } from "services/api/user";
import { setUserList } from "store/user/user.action";
const Notification = () => {
  const title = "Notifications";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setNotification = () => {
    notificationList()
      .then((res) => {
        dispatch(setNotificationList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
      userList()
      .then((res) => {
        dispatch(setUserList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setNotification();
    setLoading(false);
  }, []);

  const handleNotificationSend = (event, row) => {
    updateState("Send Notification", ()  => {return <NotificationForm/>}, () => {}, true);
  };

  const notifications = useSelector(selectNotification);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "message",
      label: "Message",
    },
    {
      name: "user_id",
      label: "User Name",
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    }
  ];

  const data = isObjectEmpty(notifications)
    ? []
    : notifications.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.message,
        item.user,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => handleNotificationSend()}
    />);
};

export default Notification;
