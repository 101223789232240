// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconCapture, IconFingerprint, IconMapPinOff, IconUserPlus, IconSquareX } from '@tabler/icons';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import { readAdminNotification } from 'services/api/adminNotification';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const { notifications, removeNotification } = useNotification();
    const [state, setState] = useState([]);

    useEffect(() => {
        setState(notifications.notifications)
    }, [notifications.notifications.length])
    
    
    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    const avatarSX = {
        border: 'none'
    };

    const chipNewFingerPunchSX = {
        ...chipSX,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light
    };

    const avatarNewFingerPunchSX = {
        ...avatarSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light,
        borderColor: theme.palette.warning.main
    };

    const chipNewFacePunchSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const avatarNewFacePunchSX = {
        ...avatarSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        borderColor: theme.palette.success.main
    };

    const chipNewRegistrationRequestSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light
    };

    const avatarNewRegistrationRequestSX = {
        ...avatarSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        borderColor: theme.palette.orange.main
    };

    const chipNewBoundaryCrossSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const avatarNewBoundaryCrossSX = {
        ...avatarSX,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main
    };

    const getSx = (type, name) => {
        switch (type) {
            case 'Finger Punch': {
                return {
                    avatar: avatarNewFingerPunchSX,
                    chip: chipNewFingerPunchSX,
                    icon: <IconFingerprint stroke={1.5} size="1.3rem" />,
                    message: `User "${name}" has successfully punched in with finger print`
                };
            }
            case 'Face Punch': {
                return {
                    avatar: avatarNewFacePunchSX,
                    chip: chipNewFacePunchSX,
                    icon: <IconCapture stroke={1.5} size="1.3rem" />,
                    message: `User "${name}" has successfully punched in with facial recognition` 
                };
            }
            case 'Boundary Cross': {
                return {
                    avatar: avatarNewBoundaryCrossSX,
                    chip: chipNewBoundaryCrossSX,
                    icon: <IconMapPinOff stroke={1.5} size="1.3rem" />,
                    message: `User "${name}" has crossed the boundary.`
                };
            }
            case 'Registration Request': {
                return {
                    avatar: avatarNewRegistrationRequestSX,
                    chip: chipNewRegistrationRequestSX,
                    icon: <IconUserPlus stroke={1.5} size="1.3rem" />,
                    message: 'New Registation Request has been sent to admin'
                };
            }
            default:
                break;
        }
    };

    const readNotificationHandler = (id) => {
        readAdminNotification(id).then(res => {
            removeNotification(id);
        })
    }

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {state.map((item) => {
                let Sx = getSx(item.type, item.user);
                return (
                    <>
                        <ListItemWrapper key={item.id}>
                            <ListItem alignItems="center">
                                
                                <ListItemAvatar>
                                    <Avatar sx={Sx.avatar}>{Sx.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.user} />
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12} flexDirection="row">
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {item.date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                                <IconButton onClick={() => readNotificationHandler(item.id)} sx={{bottom: '18px', left: '10px'}} color="error" aria-label="delete">
                                    <IconSquareX stroke={1.7} size="1.2rem" />
                                </IconButton>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2">{Sx.message}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Chip label={item.type} sx={Sx.chip} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </>
                );
            })}
            {/* <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.success.dark,
                                backgroundColor: theme.palette.success.light,
                                border: 'none',
                                borderColor: theme.palette.success.main
                            }}
                        >
                            <IconBuildingStore stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Store Verification Done</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">We have successfully received your request.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Unread" sx={chipErrorSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.primary.dark,
                                backgroundColor: theme.palette.primary.light,
                                border: 'none',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            <IconMailbox stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Check Your Mail.</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">All done! Now check your inbox as you&apos;re in for a sweet treat!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation endIcon={<IconBrandTelegram stroke={1.5} size="1.3rem" />}>
                                    Mail
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography component="span" variant="subtitle2">
                            Uploaded two file on &nbsp;
                            <Typography component="span" variant="h6">
                                21 Jan 2020
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light
                                    }}
                                >
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={2}>
                                                    <IconPhoto stroke={1.5} size="1.3rem" />
                                                    <Typography variant="subtitle1">demo.jpg</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar alt="John Doe" src={User1} />
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    2 min ago
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">It is a long established fact that a reader will be distracted</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Confirmation of Account." sx={chipSuccessSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper> */}
        </List>
    );
};

export default NotificationList;
