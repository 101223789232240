import { createSelector } from "reselect";

const selectUserReducer = (state) => {
  return state.user.userLists
};

export const selectUser = createSelector(
  [selectUserReducer],
  (state) => {
    return state
  }
);