// assets
import { IconBell, IconLocation } from '@tabler/icons';

// constant
const icons = {
    IconBell,
    IconLocation
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const locations = {
  id: 'locations',
  title: 'Locations',
  type: 'group',
  children: [
    {
      id: 'location-default',
      title: 'Locations',
      type: 'item',
      url: '/locations',
      icon: icons.IconLocation,
      breadcrumbs: false
    }
  ]
};

export default locations;
