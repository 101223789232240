import { CardContent, Chip, Divider, FormControl, Grid, TextField, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectRegistrationRequest } from "store/registration-request/registration-request.selector";
import { registrationRequestUpdate } from "services/api/registration-request";
import { gridSpacing } from "store/constant";
import SubCard from "ui-component/cards/SubCard";
import GoogleMapComponent from "ui-component/GoogleMapComponent";
import { Formik } from "formik/dist";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import useSnackbar from "hooks/useSnackbar";

const RegistrationRequestView = () => {
  const title = "Registration Request View";

  const [loading, setLoading] = useState(false);


  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const { registrationRequestId } = useParams();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const registrationRequestState = useSelector(selectRegistrationRequest);

  const currentRegistrationRequest = registrationRequestState.filter((item) => item.id == registrationRequestId)[0];
  console.log(currentRegistrationRequest);
  const handleSubmit = (values, { setFieldError }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('status', values.status);
    formData.append('remark', values.remark);

    registrationRequestUpdate(registrationRequestId, formData).then(res => {
      openSnackbar("success", res.data.message)
      setLoading(false);
      navigate("/registration-requests");
    }).catch((e) => {
      if (e.response.status == 422) {
        Object.entries(e.response.data.result).forEach(
          ([key, value]) => {
            openSnackbar("error", value[0])
            setFieldError(key, value[0])
          }

        );

      }
      setLoading(false);
    });
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + title },
            { name: "Registration Request Update" },
          ]}
        />
      </Box>
      <SimpleCard title="Registration Request Detail">
        <Grid container direction="row" justifyContent='space-around'>
          <Grid item xs={12} md={4}>

            <SubCard content={false}>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Grid container alignContent="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h4">User Detail</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              Name
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle1" color="inherit">
                                  {currentRegistrationRequest.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              Civil Id
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle1" color="inherit">
                                  {currentRegistrationRequest.civil_id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              Status
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                {(currentRegistrationRequest.status == 'requested') ? <Chip color="warning" label="Requested" /> : <></>}
                                {(currentRegistrationRequest.status == 'declined') ? <Chip color="error" label="Declined" /> : <></>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              Registration Date
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle1" color="inherit">
                                  {currentRegistrationRequest.created_at}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                  </Grid>
                </Grid>
              </CardContent>

            </SubCard>
          </Grid>
          <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'strech' }}>

            <SubCard content={false} sx={{ width: '100%' }}>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Grid container alignContent="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h4">User Location</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      currentRegistrationRequest.registration_location !== null ? 
                      <GoogleMapComponent
                        latitude={Number(currentRegistrationRequest.registration_location.latitude)}
                        longitude={Number(currentRegistrationRequest.registration_location.longitude)}
                        loadingElement={<div style={{ height: '200%' }} />}
                        containerElement={<div style={{ height: '280px' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                        />
                      : <></>
                      }
                  </Grid>
                </Grid>
              </CardContent>

            </SubCard>
          </Grid>
          <Grid item xs={12} md={12} margin={gridSpacing}>

            <SubCard content={false}>
              <CardContent>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Grid container alignContent="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h4">Update Registration Request</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={currentRegistrationRequest}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>

                          <Grid container spacing={6} direction='column'>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              <FormControl sx={{ m: 1, minWidth: 300 }}>

                                <TextField
                                  id="status-input"
                                  value={values.status}
                                  label="Status"
                                  name="status"
                                  fullWidth
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  onChange={handleChange}
                                  helperText={touched.status && errors.status}
                                  error={Boolean(touched.status && errors.status)}
                                >
                                  <option key='requested' value='requested'>Requested</option>
                                  <option key='approved' value='approved'>Approved</option>
                                  <option key='declined' value='declined'>Declined</option>

                                </TextField>
                              </FormControl>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              <TextField
                                type="text"
                                name="remark"
                                id="remark"
                                value={values.remark || ""}
                                onChange={handleChange}
                                label="Remark"
                                helperText={touched.remark && errors.remark}
                                error={Boolean(touched.remark && errors.remark)}
                                fullWidth
                              />
                            </Grid>


                          </Grid>


                          <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                          >
                            Submit
                          </LoadingButton>
                        </form>
                      )}
                    </Formik>
                  </Grid>

                </Grid>
              </CardContent>

            </SubCard>
          </Grid>

        </Grid>

        {/* <AdminForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentAdmin[0] }}
        /> */}
      </SimpleCard>
    </Container>
  );
};

export default RegistrationRequestView;
