// assets
import { IconBell, IconLocation, IconCapture } from '@tabler/icons';

// constant
const icons = {
    IconBell,
    IconLocation,
    IconCapture
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const punch = {
  id: 'punch',
  title: 'Punch',
  type: 'group',
  children: [
    {
      id: 'punch-default',
      title: 'Punch',
      type: 'item',
      url: '/punch',
      icon: icons.IconCapture,
      breadcrumbs: false
    }
  ]
};

export default punch;
