const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/localhost',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    baseUrl: "https://track-n-manage-api.tech-and-beyond.com/",
    apiVersion1:'api/v1',
    socketUrl: "https://track-n-manage-socket.tech-and-beyond.org/",
    googleMapApiKey: "AIzaSyAS1kzHr_O2Qg6cac3qn2X8WhdqhADAiRc"
    
};

export default config;
