import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { Formik } from 'formik';
import useAlert from 'hooks/useAlert';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { sendNotification } from 'services/api/notification';
import { userList } from 'services/api/user';
import { selectUser } from 'store/user/user.selector';

const NotificationForm = () => {

    const [state, setState] = useState({ 'user_id': [], message: '' });
    const [loading, setLoading] = useState(false);

    const users = useSelector(selectUser);
    const { openSnackbar } = useSnackbar();
    const { updateState } = useAlert();



    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        console.log(state);
        state.user_id.map(item => {
            console.log(item);
            
            sendNotification({user_id: item.id, message: values.message}).then((res) => {
            }).catch((e) => {
                console.log(e.response.data.result);
            });
            setLoading(false);
            openSnackbar("success", 'Notification Sent successfully')
            updateState('', () => null, () => { })
        })

    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Autocomplete
                                    multiple
                                    id="user-ids"
                                    options={users}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        setState({ ...state, user_id: newValue });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select User"
                                            placeholder="User"
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="message"
                                    id="message"
                                    value={values.message || ""}
                                    onChange={handleChange}
                                    label="Message"
                                    helperText={touched.message && errors.message}
                                    error={Boolean(touched.message && errors.message)}
                                    fullWidth
                                />

                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Send
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default NotificationForm
