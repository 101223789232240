import { Chip, IconButton } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "hooks/useAuth";
import { setUserList } from "store/user/user.action";
import { userDelete, userFacePrintReset, userFingerPrintReset, userList, userUpdateStatus } from "services/api/user";
import { selectUser } from "store/user/user.selector";
import { IconEye, IconHandOff, IconFaceId, IconBellPlus, IconLayoutList, IconMap2 } from "@tabler/icons";
import NotificationForm from "ui-component/NotificationForm";


const User = () => {
  const title = "Users";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setUser = () => {
    userList()
      .then((res) => {
        dispatch(setUserList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  const { user } = useAuth();
  useEffect(() => {
    setLoading(true);
    setUser();
    setLoading(false);
  }, []);

  const deleteHandler = (id) => {
    setLoading(false);
    userDelete(id)
      .then((res) => {
        setUser();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  
  const fingerResetHandler = (id) => {
    setLoading(false);
    userFingerPrintReset(id)
      .then((res) => {
        setUser();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  
  const faceResetHandler = (id) => {
    setLoading(false);
    userFacePrintReset(id)
      .then((res) => {
        setUser();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };

  const handleView = (id, row) => {
    navigate("/users/punch/" + row.rowData[0]);
  };

  const handleStatusUpdate = (id, value) => {
    userUpdateStatus(id)
      .then((res) => {
        setUser();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
  };
 
 

  const handleDelete = (event, row) => {
    updateState("Delete User",() => {return <h3>Are you sure you want to delete User?</h3>}, () => deleteHandler(row["rowData"][0]));
  };
  
  const handleMapView = (event, row) => {
    navigate("/users/map/" + row.rowData[0]);
  };
 
  const handleNotificationSend = (event, row) => {
    updateState("Send Notification", ()  => {return <NotificationForm/>}, () => {}, true);
  };
  
  const handleFingerReset = (event, row) => {
    updateState("Finger Reset", () => {return <h3>Are you sure you want to reset user finger?</h3>}, () => fingerResetHandler(row["rowData"][0]));
  };

  const handleFaceReset = (event, row) => {
    updateState("Face Reset", () => {return <h3>Are you sure you want to reset user face id?</h3>}, () => faceResetHandler(row["rowData"][0]));
  };

  const users = useSelector(selectUser);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "civil_id",
      label: "Civil Id",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            (tableMeta.rowData[0] == user?.id) ?
              'Active'
              :
              <SimpleSwitch
                id={tableMeta["rowData"][0]}
                value={valueData}
                changeHandler={(id, valueData) => handleStatusUpdate(id, valueData)}
              />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      name: "finger_print",
      label: "Finger Print",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            (valueData == 1) ? <Chip color="success" label="Set" /> : <Chip color="warning" label="Unset" />
            );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      name: "face_print",
      label: "Face Id",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            (valueData == 1) ? <Chip color="success" label="Set" /> : <Chip color="warning" label="Unset" />
            );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleView(e, tableMeta);
                }}
                color="secondary"
              >
                <IconEye />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleFaceReset(e, tableMeta);
                }}
                color="alert"
                >
                <IconFaceId />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleFingerReset(e, tableMeta);
                }}
                color="primary"
              >
                <IconHandOff/>
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleNotificationSend(e, tableMeta);
                }}
                color="warning"
              >
                <IconBellPlus  />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleMapView(e, tableMeta);
                }}
                color="orange"
              >
                <IconMap2 />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data = isObjectEmpty(users)
    ? []
    : users.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.name,
        item.civil_id,
        item.status == 'approved',
        item.finger_print,
        item.face_print,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  
  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => {}}
    />);
};

export default User;
