import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import BoundaryCrossLogs from "./BoundaryCrossLogs";

 

const BoundaryCrossLogsRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/boundary-cross-logs",
            element:<BoundaryCrossLogs/>
        }
    ]
}

export default BoundaryCrossLogsRoutes;