// socketService.js
import config from 'config';
import io from 'socket.io-client';

const socketUrl = config.socketUrl // Replace with your server URL

const socket = io(socketUrl);

const socketService = {
    connect: () => {
        if (!socket.connected) {
            socket.connect();
        }
    },
    disconnect: () => {
        if (socket.connected) {
            socket.disconnect();
        }
    },
    on: (eventName, callback) => {
        socket.on(eventName, callback);
    },
    off: (eventName, callback) => {
        socket.off(eventName, callback);
    },
    emit: (eventName, data) => {
        socket.emit(eventName, data);
    },
};

export default socketService;
