import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import User from "./User";
import Map from "./Map";
import UserView from "./UserView";
import UserPunch from "./UserPunch";

 

const UserRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/users",
            element:<User/>
        },
        {
            path:"/users/view/:userId",
            element:<UserView/>
        },
        {
            path:"/users/punch/:userId",
            element:<UserPunch/>
        },
        {
            path:"/users/map/:userId",
            element:<Map/>
        },
        // {
        //     path:"/admins/edit/:userId",
        //     element:<AdminUpdate/>
        // },
    ]
}

export default UserRoutes;