import { createSelector } from "reselect";

const selectBoundaryCrossLogReducer = (state) => {
  return state.boundaryCrossLog.boundaryCrossLogLists
};

export const selectBoundaryCrossLog = createSelector(
  [selectBoundaryCrossLogReducer],
  (state) => {
    return state
  }
);