import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import RegistrationRequest from "./RegistrationRequest";
import RegistrationRequestView from "./RegistrationRequestView";

 

const RegistrationRequestRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/registration-requests",
            element:<RegistrationRequest/>
        },
        {
            path:"/registration-requests/view/:registrationRequestId",
            element:<RegistrationRequestView/>
        }
    ]
}

export default RegistrationRequestRoutes;