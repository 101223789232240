import { Chip, IconButton } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import { setUserList } from "store/user/user.action";
import { IconMapPin } from "@tabler/icons";
import { userPunchList } from "services/api/punch";
import GoogleMapComponent from "ui-component/GoogleMapComponent";


const UserPunch = () => {
  const title = "User Punch";
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const { openSnackbar } = useSnackbar();
  const { userId } = useParams();



  const setUserPunch = () => {
    userPunchList(userId)
      .then((res) => {
        setState(res.data.result.data);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setUserPunch();
    setLoading(false);
  }, []);


  const handleLocationView = (event, lat, long) => {
    updateState(
      "Punch Location",
      () => {
        return <GoogleMapComponent
          latitude={Number(lat)}
          longitude={Number(long)}
          loadingElement={<div style={{ height: '200%', width: '200%' }} />}
          containerElement={<div style={{ height: '380px', width: '380px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      },
      () => { },
      true
    );
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Punch Type",
    },
    {
      name: "location",
      name: "Location",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(value);
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleLocationView(e, value.latitude, value.longitude);
                }}
                color="secondary"
              >
                <IconMapPin />
              </IconButton>
            </>
          );
        },
      },
    },
    {
      label: "Create Date",
      name: 'created_at',
      options: {
        filter: false,
      },
    },
   
  ];

  const data = isObjectEmpty(state)
    ? []
    : state.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.type,
        item.location,
        item.created_at,
      ];
    });

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };

  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => { }}
    />);
};

export default UserPunch;
