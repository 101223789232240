import { createSelector } from "reselect";

const selectNotificationReducer = (state) => {
  return state.notification.notificationLists
};

export const selectNotification = createSelector(
  [selectNotificationReducer],
  (state) => {
    return state
  }
);